<template>
  <v-main>
    <v-container>
      <Cms />
    </v-container>
  </v-main>
</template>

<script>
import Cms from '../components/cms/index.vue';
export default {
  components: {
    Cms
  }
};
</script>

<style>
</style>
