<template>
  <div>
    <v-dialog v-model="dialogs.password" width="350" persistent>
      <v-card class="mx-auto">
        <v-card-title class=""
          >Enter password to continue
          <v-spacer></v-spacer>
          <v-btn icon @click="$router.push('/')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <v-form ref="passwordForm" @submit.prevent="submitPassword()">
            <BasePasswordField v-model="password" outlined />
            <AlertMessage :error="errors.password" />
            <v-btn color="primary" class="text-capitalize" block large @click="submitPassword()"
              >Submit</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="detail">
      <div
        v-if="detail.backgroundImageURL && detail.backgroundImageURL.url"
        id="bg-image"
        class="bg-image"
        :style="{
          'background-image': `${style.gradient ? `linear-gradient(${style.gradient}), ` : ''}url(${
            detail.backgroundImageURL.url
          })`,
          ...style
        }"
      ></div>
      <div
        :class="{
          'bg-content': detail.backgroundImageURL && detail.backgroundImageURL.url
        }"
      >
        <v-container v-if="contentVisible">
          <CmsRow :detail="detail" />
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { parseJson } from '../../utils/';
import { get, privacy } from './cms';
import CmsRow from './Row.vue';

function NotFoundError(message = '') {
  this.name = 'NotFoundError';
  this.message = message;
  this.statusCode = 404;
}
NotFoundError.prototype = Error.prototype;

export default {
  components: {
    CmsRow
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      detail: null,
      dialogs: {
        password: false
      },
      password: '',
      passwordMatched: false,
      errors: {
        password: ''
      }
    };
  },
  computed: {
    url() {
      return this.$route.path || '/';
    },
    style() {
      try {
        const res = parseJson(this.detail.backgroundImageStyle);
        return res || {};
      } catch {
        return {};
      }
    },
    contentVisible() {
      if (this.detail && this.detail.password && !this.passwordMatched) {
        return false;
      }
      return true;
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value) {
          this.detail = value;
        }
      },
      immediate: true
    }
  },
  async mounted() {
    if (!this.value) {
      await this.getPage();
    } else {
      this.updateBackgrounImagedHeight();
      this.verifyPassword();
    }
  },

  methods: {
    parseJson,
    verifyPassword() {
      if (this.detail.requireLogin) {
        const loggedIn = this.$storage.getUniversal('loggedIn');
        if (!loggedIn) {
          this.$router.push(`/login?next=${this.$route.path}`);
        }
      }
      if (this.detail.password) {
        this.dialogs.password = true;
        this.autoSubmitPassword();
      }
    },
    autoSubmitPassword() {
      const savedPassword =
        this.$route.query.password || this.$storage.getUniversal(this.$route.path) || '';
      if (savedPassword) {
        this.password = savedPassword;
        this.submitPassword();
      }
    },
    submitPassword() {
      if (String(this.password) === String(this.detail.password)) {
        this.passwordMatched = true;
        this.dialogs.password = false;

        this.$storage.setUniversal(this.$route.path, this.password);
      } else {
        this.errors.password = 'Incorrect password';
        setTimeout(() => {
          this.errors.password = '';
        }, 5000);
      }
    },
    updateBackgrounImagedHeight() {
      setTimeout(() => {
        const fullHeight = Math.max(
          document.body.scrollHeight,
          document.documentElement.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.offsetHeight,
          document.body.clientHeight,
          document.documentElement.clientHeight
        );
        const el = document.getElementById('bg-image');
        if (el) {
          document.getElementById('bg-image').style.height = fullHeight + 'px';
        }
      }, 100);
    },
    notFound() {
      this.$router.push('/404');
      throw new NotFoundError('Page not found');
    },
    async getPage() {
      const query = {};
      if (/^\/~.*/.test(this.url)) {
        query.key = this.url.split('/~')[1];
      } else {
        query.url = this.url;
      }

      const docs = await get({
        ...query,
        type: 'page',
        privacy: 'all'
      });
      if (!docs.docs.length) {
        this.notFound();
      }
      const detail = { ...docs.docs[0].data(), id: docs.docs[0].id };
      this.updateBackgrounImagedHeight();
      if (detail.privacy === privacy.public) {
        this.detail = detail;
        if (this.detail.redirect) {
          window.location = this.detail.redirect;
        }
      } else if (this.$store.state.isAdmin) {
        const uid = this.$store.state.user.uid;
        if (detail.user === uid || detail.privacy === privacy.admin) {
          this.detail = detail;
        } else {
          this.notFound();
        }
      } else {
        this.notFound();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  min-width: 100%;
  min-height: 100vh;
}
.bg-content {
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
